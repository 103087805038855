import {
  createContext,
  type FC,
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef
} from 'react';

export interface NavigateLessonEvent {
  isNavigatingForward: boolean;
  isCompleteLesson: boolean;
  onEvent?: () => void;
}

export type KeyboardHandlerReturnType = boolean | void | Promise<boolean | void>;

/**
 * Add additional event types here.
 * When events are triggered, an optional parameter can be passed.
 * Navigate events have a boolean parameter that specifies whether to navigate forwards or backwards.
 */
type EventWithNoParams = 'refresh-editor' | 'submit-assessment' | 'navigate-lesson';

type EventHandler =
  | { name: EventWithNoParams; handler: () => void }
  | { name: 'keydown'; handler: (e: KeyboardEvent) => KeyboardHandlerReturnType }
  | { name: 'navigate-lesson'; handler: (e: NavigateLessonEvent) => void };

export type EventTrigger =
  | EventWithNoParams
  | ({ name: 'navigate-lesson' } & NavigateLessonEvent);

export type EventListener = EventHandler & {
  // Event handlers with a higher priority will be triggered first.
  isActive?: boolean;
  hasDependencies?: boolean;
  priority?: number;
};

export type ModifyEventListener = (listener: EventListener) => void;
export type TriggerEventCallback = (e: EventTrigger) => Promise<void | boolean>;

export const EventsContext = createContext(
  {} as {
    triggerEvent: TriggerEventCallback;
    addEventListener: ModifyEventListener;
    removeEventListener: ModifyEventListener;
  }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Iteratee<T> = (item: T) => any;

function sortBy<T>(array: T[], iteratee: keyof T | Iteratee<T>): T[] {
  return array.slice().sort((a, b) => {
    const valueA =
      typeof iteratee === 'function' ? (iteratee as Iteratee<T>)(a) : a[iteratee];
    const valueB =
      typeof iteratee === 'function' ? (iteratee as Iteratee<T>)(b) : b[iteratee];

    if (valueA > valueB) {
      return 1;
    } else if (valueA < valueB) {
      return -1;
    }
    return 0;
  });
}

export const EventsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const eventListenersRef = useRef<Partial<Record<string, EventListener[]>>>({});

  const triggerEvent: TriggerEventCallback = useCallback(async (eventParams) => {
    const hasParams = typeof eventParams === 'object';
    const name = hasParams ? eventParams.name : eventParams;
    const params = hasParams ? eventParams : undefined;
    const handlers = eventListenersRef.current[name] ?? [];

    for (const handler of handlers) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = await handler.handler(params as any);

      // If a handler returns false, stop triggering events
      if (result === false) {
        return false;
      }
    }
  }, []);

  const addEventListener: ModifyEventListener = useCallback((listener) => {
    const { name, isActive = true } = listener;
    if (isActive) {
      eventListenersRef.current[name] = sortBy(
        [...(eventListenersRef.current[name] ?? []), listener],
        (e) => e.priority ?? 0
      ).reverse();
    }
  }, []);

  const removeEventListener: ModifyEventListener = useCallback((listener) => {
    const existingListeners = eventListenersRef.current[listener.name] ?? [];
    eventListenersRef.current[listener.name] = existingListeners.filter(
      (currentListener) => currentListener !== listener
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      addEventListener,
      removeEventListener,
      triggerEvent
    }),
    [addEventListener, removeEventListener, triggerEvent]
  );

  return <EventsContext.Provider value={contextValue}>{children}</EventsContext.Provider>;
};

export const useTriggerEvent = () => useContext(EventsContext).triggerEvent;

export const useEvent = (event: EventListener) => {
  const { addEventListener, removeEventListener } = useContext(EventsContext);

  useEffect(() => {
    addEventListener(event);

    return () => {
      removeEventListener(event);
    };
    // For better DX, we default the event handler to NOT be a dependency.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addEventListener,
    event.isActive,
    event.name,
    event.priority,
    removeEventListener,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    event.hasDependencies && event.handler
  ]);
};
