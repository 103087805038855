import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

export const pageSchema = z.enum(['curriculum', 'settings', 'publish']);

const paramsSchema = z.object({
  courseId: z.string(),
  pageId: pageSchema.catch('curriculum')
});

const searchSchema = z.object({
  lessonId: z.string().cuid().optional().catch(undefined),
  chapterId: z.string().cuid().optional().catch(undefined),
  mode: z.enum(['edit', 'create']).optional(),
  preview: z.boolean().optional().catch(false)
});

export const Route = createFileRoute(
  '/_auth/_courseEditor/edit/course/$courseId/$pageId'
)({
  validateSearch: (search) => searchSchema.parse(search),
  parseParams: (params) => paramsSchema.parse(params),
  component: lazyRouteComponent(() => import('features/CourseEditor'))
});

export const useCourseEditorParams = () => {
  const { courseId, pageId } = Route.useParams();
  const { lessonId, chapterId, mode, preview } = Route.useSearch();
  const navigate = Route.useNavigate();
  return { courseId, pageId, lessonId, chapterId, mode, isPreview: preview, navigate };
};
