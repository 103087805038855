import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

const schema = z.object({
  question: z.number().positive().default(0).catch(0)
});

const paramsSchema = z.object({
  assessmentId: z.string().cuid()
});

export const Route = createFileRoute('/_auth/_assessmentPlayer/assessment/$assessmentId')(
  {
    validateSearch: (search) => schema.parse(search),
    parseParams: (params) => paramsSchema.parse(params),
    component: lazyRouteComponent(() => import('pages/Authenticated/Question/Attempt'))
  }
);
