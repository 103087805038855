import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Button from 'components/atoms/Button';
import { type FC } from 'react';

export const ConfirmationDialogMinimal: FC<{
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title?: string;
  description?: string;
  color?: 'primary' | 'secondary' | 'error' | 'success' | 'warning';
}> = ({
  isOpen,
  handleClose,
  handleConfirm,
  title = 'Confirm Delete',
  description = 'Are you sure you want to delete? This action cannot be undone.',
  color = 'error'
}) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
        {description}
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color={color}>
          Confirm
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);
