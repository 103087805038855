import { useNavigate, useParams, useSearch } from '@tanstack/react-router';

export const useCourseId = () =>
  useParams({
    strict: false,
    select(params) {
      if (!params.courseId) {
        throw new Error('courseId is required');
      }
      return params.courseId;
    }
  });

export const useProductId = () =>
  useParams({
    strict: false,
    select(params) {
      if (!params.productId) {
        throw new Error('productId is required');
      }
      return params.productId;
    }
  });

export const useAssessmentId = () =>
  useParams({
    strict: false,
    select(params) {
      if (!params.assessmentId) {
        throw new Error('assessmentId is required');
      }
      return params.assessmentId;
    }
  });

export const useQuestionId = () =>
  useParams({
    strict: false,
    select(params) {
      if (!params.questionId) {
        throw new Error('questionId is required');
      }
      return params.questionId;
    }
  });

export const useQuestionOrder = ({ max }: { max: number }) => {
  const navigate = useNavigate();
  const { question: questionOrder } = useSearch({
    strict: false
  });

  const setQuestionOrder = (order: number) => {
    navigate({
      search: (p) => ({
        ...p,
        question: order
      })
    });
  };

  const minOrder = 1;

  const order = questionOrder
    ? questionOrder > max
      ? max
      : questionOrder < minOrder
        ? minOrder
        : questionOrder
    : minOrder;

  return { questionOrder: order, setQuestionOrder };
};
