import { authz, permissions } from './permissions';
import { type Authz, type Role, type Roles } from './types';

// !important: ensure that the permissions/roles are not in the database if changing/removing

const rolesRaw: Roles = [
  {
    id: 'SUPER_ADMIN',
    name: 'Super Admin',
    description: 'This role gives full access to the dashboard',
    requiredPermissions: [authz.roles_view_all]
  },
  {
    id: 'ADMIN',
    name: 'Admin',
    description: 'This role gives access to the dashboard',
    requiredPermissions: [authz.roles_view_admin]
  },
  {
    id: 'COURSE_EDITOR',
    name: 'Course Editor',
    description: 'This role gives access to the dashboard',
    requiredPermissions: [authz.roles_view_course_editor]
  },
  {
    id: 'INSTRUCTOR',
    name: 'Instructor',
    description: 'This role gives access to the dashboard',
    requiredPermissions: [authz.roles_view_instructor]
  },
  {
    id: 'LEARNER',
    name: 'Learner',
    description: 'This role gives access to the dashboard',
    requiredPermissions: [authz.roles_view_learner]
  }
];

export const roles = rolesRaw.map((role) => {
  const rolePermissions = permissions
    .filter((p) => p.roles.includes(role.id) || role.id === 'SUPER_ADMIN')
    .map(({ id }) => id);

  return {
    id: role.id,
    name: role.name,
    description: role.description,
    requiredPermissions: role.requiredPermissions,
    permissions: rolePermissions
  };
});

export const getViewableRoles = (userPermissions?: Set<Authz>) =>
  roles.filter((role) =>
    role.requiredPermissions.every((permission) => userPermissions?.has(permission))
  );

export const getViewableUsers = <T extends { roles: Set<Role> }>(
  userPermissions: Set<Authz>,
  users: T[]
) => {
  const viewableRoles = getViewableRoles(userPermissions);

  return users.filter((user) => {
    const userRoles = Array.from(user.roles);

    return userRoles.every((role) =>
      viewableRoles.some((viewableRole) => viewableRole.id === role)
    );
  });
};
