export const blue = {
  lightest: '#EFF6FF',
  light: '#DBEAFE',
  main: '#2563EB',
  dark: '#1E40AF',
  darkest: '#1E3A8A',
  contrastText: '#FFFFFF'
};

export const cyan = {
  lightest: '#ECFEFF',
  light: '#CFFAFE',
  main: '#06B6D4',
  dark: '#0891B2',
  darkest: '#155E75',
  contrastText: '#FFFFFF'
};

export const sky = {
  lightest: '#F0F9FF',
  light: '#E0F2FE',
  main: '#0EA5E9',
  dark: '#075985',
  darkest: '#0C4A6E',
  contrastText: '#FFFFFF'
};

export const orange = {
  lightest: '#FFEDD5',
  light: '#FED7AA',
  main: '#EA580C',
  dark: '#C2410C',
  darkest: '#9A3412',
  contrastText: '#FFFFFF'
};

export const amber = {
  lightest: '#FFFBEB',
  light: '#FEF3C7',
  main: '#F59E0B',
  dark: '#B45309',
  darkest: '#92400E',
  contrastText: '#FFFFFF'
};

export const green = {
  lightest: '#DCFCE7',
  light: '#BBF7D0',
  main: '#16A34A',
  dark: '#15803D',
  darkest: '#166534',
  contrastText: '#FFFFFF'
};

export const indigo = {
  lightest: '#EEF2FF',
  light: '#C7D2FE',
  main: '#4F46E5',
  dark: '#4338CA',
  darkest: '#3730A3',
  contrastText: '#FFFFFF'
};

export const purple = {
  lightest: '#F9F5FF',
  light: '#F4EBFF',
  main: '#9E77ED',
  dark: '#6941C6',
  darkest: '#42307D',
  contrastText: '#FFFFFF'
};

export const success = {
  lightest: '#F0FDF9',
  light: '#3FC79A',
  main: '#10B981',
  dark: '#0B815A',
  darkest: '#134E48',
  contrastText: '#FFFFFF'
};

export const info = {
  lightest: '#ECFDFF',
  light: '#CFF9FE',
  main: '#06AED4',
  dark: '#0E7090',
  darkest: '#164C63',
  contrastText: '#FFFFFF'
};

export const warning = {
  lightest: '#FFFAEB',
  light: '#FEF0C7',
  main: '#F79009',
  dark: '#B54708',
  darkest: '#7A2E0E',
  contrastText: '#FFFFFF'
};

export const error = {
  lightest: '#FEF3F2',
  light: '#FEE4E2',
  main: '#F04438',
  dark: '#B42318',
  darkest: '#7A271A',
  contrastText: '#FFFFFF'
};

export const dark = {
  lightest: '#F9FAFB',
  light: '#2D3748',
  main: '#1A202C',
  dark: '#111827',
  darkest: '#090C10',
  contrastText: '#FFFFFF'
};

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24'
};

export const common = {
  black: '#000000',
  white: '#FFFFFF'
};

export const background = {
  paper: '#FFFFFF',
  default: '#F4F6F8'
};

export const colors = {
  white: '#ffffff',
  lightWhite: '#eceff1'
};
