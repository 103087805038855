import Stack from '@mui/material/Stack';

import { useMemo } from 'react';
import { useAuth } from 'utils/contexts/AuthContext';
import { type NavProps } from '../types';
import NavList from './nav-list';

// ----------------------------------------------------------------------

export default function NavDesktop({ data, sx, ...other }: NavProps) {
  const { isAuthenticated } = useAuth();
  const navList = useMemo(
    () =>
      data.filter((list) => {
        if (list.isAuthenticated && !isAuthenticated) {
          return false;
        }

        if (list.isMobileOnly) {
          return false;
        }

        return true;
      }),
    [data, isAuthenticated]
  );
  return (
    <Stack
      component="nav"
      direction="row"
      spacing={5}
      sx={{
        height: 1,
        ...sx
      }}
      {...other}
    >
      {navList.map((list) => (
        <NavList key={list.title} data={list} />
      ))}
    </Stack>
  );
}
