import { roles as rolesList } from './roles';
import { type Authz, type Role } from './types';

export * from './permissions';
export * from './roles';
export * from './types';

export const getUserAuthz = (user: {
  roles: { role: Role }[];
  permissions: { permission: string }[];
}) => {
  const roles = new Set(user.roles.map((ur) => ur.role));
  const rolePermissions = rolesList.reduce((acc, role) => {
    if (roles.has(role.id)) {
      return [...acc, ...role.permissions];
    }

    return acc;
  }, [] as Authz[]);

  const permissions = new Set([
    ...(user.permissions.map((up) => up.permission) as Authz[]),
    ...rolePermissions
  ]);

  return { permissions, roles };
};
