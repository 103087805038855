import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

const SearchParamsSchema = z.object({
  session_id: z.string()
});

export const Route = createFileRoute('/_auth/_profile/order')({
  validateSearch: (p) => SearchParamsSchema.parse(p),
  component: lazyRouteComponent(() => import('pages/Standard/Checkout/Confirmation'))
});

export const useOrderParams = () => Route.useSearch();
