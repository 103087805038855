import Button from 'components/atoms/Button';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useAuth } from './AuthContext';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;
  width: 100vw;
`;

export const UserProvider: FC<PropsWithChildren> = ({ children }) => {
  // TODO:  Level 2 - Improve UX and UI

  // This component essentially displays only the error message if there is an error
  const auth = useAuth();

  if (auth.isError) {
    return (
      <Container>
        <h1>Something went wrong</h1>
        <p>{auth.errorMessage}</p>
        <Button onClick={() => auth.logout}>Home</Button>
      </Container>
    );
  }
  return <>{children}</>;
};
