import { createContext, useCallback, useContext, useState, type ReactNode } from 'react';

export type MenuProps = {
  selectedItem: string[];
  drawerOpen: boolean;
};

const initialState: MenuProps = {
  selectedItem: ['dashboard'],
  drawerOpen: true
};

const MenuContext = createContext<{
  state: MenuProps;
  activeItem: (selectedItem: string[]) => void;
  openDrawer: (drawerOpen: boolean) => void;
}>({
  state: initialState,
  activeItem: () => {},
  openDrawer: () => {}
});

export const MenuProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<MenuProps>(initialState);

  const activeItem = useCallback((selectedItem: string[]) => {
    setState((prevState) => ({
      ...prevState,
      selectedItem
    }));
  }, []);

  const openDrawer = useCallback((drawerOpen: boolean) => {
    setState((prevState) => ({
      ...prevState,
      drawerOpen
    }));
  }, []);

  return (
    <MenuContext.Provider value={{ state, activeItem, openDrawer }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuContext = () => useContext(MenuContext);
