import { Link as RouterLink, type LinkOptions } from '@tanstack/react-router';
import { forwardRef } from 'react';

interface LinkProps extends LinkOptions {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

// Use React.forwardRef to forward refs to RouterLink
const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, children, className, onClick, ...rest }, ref) => (
    <RouterLink
      to={to}
      {...rest}
      preload="intent"
      className={className}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </RouterLink>
  )
);

// Provide a display name for better debugging
Link.displayName = 'ForwardedLink';

export default Link;
