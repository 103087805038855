import { createRootRoute, Outlet } from '@tanstack/react-router';
import Loader from 'components/atoms/Loader';
import ErrorBoundary from 'components/molecules/ErrorBoundary';
import { ErrorScreen } from 'components/molecules/ErrorScreen';
import UnhandledErrorBoundary from 'components/molecules/UnhandledErrorBoundary';
import { ToastProvider } from 'lib/Notify';
import NotFound from 'pages/ErrorPages/404';
import { lazy, Suspense } from 'react';
import { CartProvider } from 'utils/contexts/StoreContext';
import { UserProvider } from 'utils/contexts/UserContext';

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      );

export const Route = createRootRoute({
  notFoundComponent: () => <NotFound />,
  loader: () => <Loader />,
  component: () => (
    <UnhandledErrorBoundary>
      <ErrorBoundary
        fallback={(error, handleResetError) => (
          <ErrorScreen onResetError={handleResetError} error={error} />
        )}
      >
        <Suspense>
          <CartProvider>
            <UserProvider>
              <ToastProvider />
              <Outlet />
              <Suspense>
                <TanStackRouterDevtools />
              </Suspense>
            </UserProvider>
          </CartProvider>
        </Suspense>
      </ErrorBoundary>
    </UnhandledErrorBoundary>
  )
});
