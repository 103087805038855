import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';

import { clearRedirectPath, setRedirectPath } from 'utils/hooks/localStorage/utils';
import { z } from 'zod';

const SearchParamsSchema = z.object({
  sessionId: z.string().optional()
});
export const Route = createFileRoute('/_auth/_profile/checkout')({
  validateSearch: SearchParamsSchema.parse,
  component: lazyRouteComponent(() => import('pages/Standard/Checkout/Checkout')),
  onEnter: () => {
    setRedirectPath('/checkout');
  },
  onLeave: () => {
    clearRedirectPath();
  }
});

export const useCheckoutParams = () => Route.useSearch();
