import { ProductTypeSchema } from '@simplify-aviation/shared/products/types';
import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { NotFound } from 'pages/ErrorPages/NotFound';
import { z } from 'zod';

const ParamsSchema = z.object({
  productType: ProductTypeSchema,
  productUrl: z.string()
});

export const Route = createFileRoute('/_landing/shop/$productType/$productUrl')({
  parseParams: (p) => ParamsSchema.parse(p),
  errorComponent: () => <NotFound />,
  component: lazyRouteComponent(() => import('pages/Standard/Product'), 'ProductScreen')
});

export const useProductParams = () => {
  const params = Route.useParams();
  const navigate = Route.useNavigate();

  return { params, navigate };
};
