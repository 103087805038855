import { IconButton } from '@mui/material';
import { ProductSubscription } from '@simplify-aviation/shared/products/types';
import { Link } from '@tanstack/react-router';
import { useProducts } from 'api/products';
import Button from 'components/atoms/Button';
import ResponsiveImage from 'components/atoms/ResponsiveImage';
import { MinusIcon, PlusIcon } from 'lib/Icons';
import { type FC } from 'react';
import { useCart } from 'utils/contexts/StoreContext';
import { getPrice } from '../utils';

export const Product: FC<{
  productId: string;
  pricingId: string;
  quantity: number;
}> = ({ pricingId, productId, quantity }) => {
  const products = useProducts();
  const { removeFromCart, addToCart, isBulkEnrolEnabled } = useCart();

  const product = products.find((p) => p.id === productId);

  if (!product) {
    return null;
  }

  const pricing = product.pricing.find((p) => p.id === pricingId);

  if (!pricing) {
    return null;
  }

  return (
    <li className="flex py-6">
      <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
        <ResponsiveImage
          imagePath={product.image}
          imageName={product.title}
          className="h-full w-full object-cover object-center"
        />
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-gray-900">
            <h3>
              <Link
                to={`/shop/$productType/$productUrl`}
                params={{ productType: product.type, productUrl: product.url }}
              >
                {product.title}
              </Link>
            </h3>
            <p className="ml-4">
              {getPrice({
                ...pricing,
                isSubscription:
                  pricing.isSubscription === ProductSubscription.SUBSCRIPTION,
                isShortVersion: true
              })}
            </p>
          </div>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <div className="flex items-center gap-2">
            {isBulkEnrolEnabled && (
              <>
                <p className="text-gray-500">Qty {quantity}</p>
                <div className="flex gap-1">
                  <IconButton
                    size="small"
                    onClick={() => {
                      removeFromCart({
                        pricingId,
                        quantity: 1
                      });
                    }}
                  >
                    <MinusIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      addToCart({
                        productId,
                        pricingId,
                        quantity: 1,
                        price: pricing.price
                      });
                    }}
                  >
                    <PlusIcon />
                  </IconButton>
                </div>
              </>
            )}
          </div>
          <div className="flex">
            <Button
              variant="text"
              onClick={() => {
                removeFromCart({ pricingId, quantity });
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </li>
  );
};
