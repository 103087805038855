// !important: ensure that the permissions/roles are not in the database if changing/removing

import { type Permissions, type Role } from './types';

export const authz = {
  organisation_full_access: 'organisation:full-access',
  organisation_read_roles: 'organisation:read:roles',
  organisation_assign_role: 'organisation:assign:role',
  organisation_assign_permission: 'organisation:assign:permission',
  dashboard_manage_users: 'dashboard:manage:users',
  dashboard_manage_courses: 'dashboard:manage:courses',

  assessments_view_all: 'assessments:view:all',
  assessments_delete_all: 'assessments:delete:all',
  assessments_update: 'assessments:update',
  assessment_attempt: 'assessment:attempt',

  course_view_all: 'course:view:all',
  course_edit_all: 'course:edit:all',

  roles_view_all: 'roles:view:all',
  roles_view_admin: 'roles:view:admin',
  roles_view_course_editor: 'roles:view:course-editor',
  roles_view_learner: 'roles:view:learner',
  roles_view_instructor: 'roles:view:instructor',

  course_assign_users: 'course:assign:users',
  media_add: 'media:add',

  manage_products: 'products:all',

  products_admin_view: 'products:admin:view',
  products_admin_edit: 'products:admin:edit',
  products_admin_create: 'products:admin:create',

  bulk_enroll: 'bulk:enroll'
} as const;

const productPermissions: Permissions = [
  {
    id: authz.manage_products,
    name: 'Manage Products',
    description: 'This permission gives access to manage products',
    roles: ['ADMIN']
  },
  {
    id: authz.products_admin_view,
    name: 'Products Admin View',
    description: 'This permission gives access to view products',
    roles: ['ADMIN']
  },
  {
    id: authz.products_admin_edit,
    name: 'Products Admin Edit',
    description: 'This permission gives access to edit products',
    roles: ['ADMIN']
  },
  {
    id: authz.products_admin_create,
    name: 'Products Admin Create',
    description: 'This permission gives access to create products',
    roles: ['ADMIN']
  }
];

const coursePermissions: Permissions = [
  {
    id: authz.course_view_all,
    name: 'Course View All',
    description: 'This permission gives access to view all courses',
    roles: ['ADMIN', 'COURSE_EDITOR', 'LEARNER']
  },
  {
    id: authz.course_edit_all,
    name: 'Course Edit All',
    description: 'This permission gives access to edit all courses',
    roles: ['ADMIN', 'COURSE_EDITOR']
  },
  {
    id: authz.course_assign_users,
    name: 'Course Assign Users',
    description: 'This permission gives access to assign users to a course',
    roles: ['ADMIN']
  },
  {
    id: authz.media_add,
    name: 'Media Add',
    description: 'This permission gives access to add media',
    roles: ['ADMIN', 'COURSE_EDITOR']
  },
  {
    id: authz.bulk_enroll,
    name: 'Bulk Enroll',
    description: 'This permission gives access to bulk enroll users',
    roles: ['ADMIN', 'INSTRUCTOR']
  }
];

const assessmentPermissions: Permissions = [
  {
    id: authz.assessments_view_all,
    name: 'Assessments View All',
    description: 'This permission gives access to view all assessments',
    roles: ['ADMIN', 'COURSE_EDITOR']
  },
  {
    id: authz.assessments_delete_all,
    name: 'Assessments Delete All',
    description: 'This permission gives access to delete all assessments',
    roles: ['ADMIN', 'COURSE_EDITOR']
  },
  {
    id: authz.assessments_update,
    name: 'Assessments Update',
    description: 'This permission gives access to update assessments',
    roles: ['ADMIN', 'COURSE_EDITOR']
  },
  {
    id: authz.assessment_attempt,
    name: 'Assessment Attempt',
    description: 'This permission gives access to attempt assessments',
    roles: ['ADMIN', 'COURSE_EDITOR', 'LEARNER']
  }
];

const organisationPermissions: Permissions = [
  {
    id: authz.organisation_full_access,
    name: 'Organisation Full Access',
    description: 'This permission gives full access to the organisation',
    roles: [] // !important: this is a special permission that is not assigned to any role
  },
  {
    id: authz.organisation_read_roles,
    name: 'Organisation Read Roles',
    description: 'This permission gives read access to the roles in the organisation',
    roles: ['ADMIN']
  },
  {
    id: authz.organisation_assign_role,
    name: 'Organisation Create Role',
    description: 'This permission gives create access to the roles in the organisation',
    roles: ['ADMIN']
  },
  {
    id: authz.organisation_assign_permission,
    name: 'Organisation Assign Permission',
    description: 'This permission gives access to assign permissions in the organisation',
    roles: ['ADMIN']
  }
];

const dashboardPermissions: Permissions = [
  {
    id: authz.dashboard_manage_users,
    name: 'Dashboard Manage Users',
    description: 'This permission gives access to manage users in the dashboard',
    roles: ['ADMIN']
  },
  {
    id: authz.dashboard_manage_courses,
    name: 'Dashboard Manage Courses',
    description: 'This permission gives access to manage courses in the dashboard',
    roles: ['ADMIN']
  }
];

const rolesPermissions: Permissions = [
  {
    id: authz.roles_view_all,
    name: 'Roles View All',
    description: 'This permission gives access to view all roles',
    roles: []
  },
  {
    id: authz.roles_view_admin,
    name: 'Roles View Admin',
    description: 'This permission gives access to view admin roles',
    roles: []
  },
  {
    id: authz.roles_view_course_editor,
    name: 'Roles View Course Editor',
    description: 'This permission gives access to view course editor roles',
    roles: ['ADMIN']
  },
  {
    id: authz.roles_view_learner,
    name: 'Roles View Learner',
    description: 'This permission gives access to view learner roles',
    roles: ['ADMIN']
  },
  {
    id: authz.roles_view_instructor,
    name: 'Roles View Instructor',
    description: 'This permission gives access to view instructor roles',
    roles: ['ADMIN']
  }
];

export const permissions: Permissions = [
  ...organisationPermissions,
  ...dashboardPermissions,
  ...assessmentPermissions,
  ...rolesPermissions,
  ...coursePermissions,
  ...productPermissions
];

export const getViewablePermissions = (role?: Set<Role>) =>
  permissions.filter((p) =>
    p.roles.some((r) => role?.has(r) || role?.has('SUPER_ADMIN'))
  );
