import {
  AttributesSchema,
  isDirectiveAttribute
} from '@simplify-aviation/shared/markdown/directive';

export const conceptsSeparator = ';';

export const convertAttributeValue = (value: string, type: string) => {
  if (type === 'array') {
    const separator = value.includes(conceptsSeparator) ? conceptsSeparator : ',';
    const values = value.split(separator);
    const isNumbers = values.every((v) => !Number.isNaN(Number(v)));
    return isNumbers ? values.map(Number) : values;
  }

  if (type === 'number') {
    return Number(value);
  }

  if (type === 'boolean') {
    return value !== 'false';
  }

  return value;
};

type AttributeValue = boolean | number | string | number[] | string[];

export const convertDirectiveAttributeValue = (
  name: string,
  value: string
): AttributeValue => {
  if (isDirectiveAttribute(name)) {
    const field = AttributesSchema.shape[name]._def.innerType._def.typeName
      .replace('Zod', '')
      .toLowerCase();

    return convertAttributeValue(value, field);
  }
  return value;
};

export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');
