import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

const assessmentTabs = z.enum(['questions', 'marking', 'settings', 'attempts']);
const schema = z.object({
  tab: assessmentTabs.optional().catch('questions'),
  topic: z.string().optional()
});

export type AssessmentTab = z.infer<typeof assessmentTabs>;
export const Route = createFileRoute(
  '/_auth/_dashboard/dashboard/assessments/edit/$assessmentId'
)({
  validateSearch: (search) => schema.parse(search),
  component: lazyRouteComponent(() => import('pages/Authenticated/Assessments/Details'))
});
