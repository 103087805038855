import { api } from 'api/trpc';

export const useLoginQuery = ({
  enabled,
  email,
  firstName,
  lastName,
  image
}: {
  enabled: boolean;
  email: string;
  firstName: string;
  lastName: string;
  image?: string;
}) =>
  api.users.auth.login.useQuery(
    { email, firstName, lastName, image },
    {
      enabled,
      refetchOnWindowFocus: true
    }
  );

export const useVerifyEmailMutation = () => api.users.auth.verifyEmail.useMutation();
