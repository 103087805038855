import ThemeCustomization from 'themes';
import { ConfigProvider } from 'utils/contexts/ConfigContext';

import { MotionLazy } from 'components/atoms/Motion';
import { AuthContext } from 'utils/contexts/AuthContext';
import { EventsContextProvider } from 'utils/contexts/EventsContext';
import { MenuProvider } from 'utils/contexts/MenuContext';
import { RouterContext } from 'utils/contexts/RouterContext';
import { TRPCContext } from 'utils/contexts/TrpcContext';
import './assets/fonts.css';
import './assets/main.css';

const App = () => (
  <AuthContext>
    <TRPCContext>
      <ThemeCustomization>
        <EventsContextProvider>
          <ConfigProvider>
            <MotionLazy>
              <MenuProvider>
                <RouterContext />
              </MenuProvider>
            </MotionLazy>
          </ConfigProvider>
        </EventsContextProvider>
      </ThemeCustomization>
    </TRPCContext>
  </AuthContext>
);

export default App;
