import { ShoppingBagIcon } from 'lib/Icons';
import { useCart } from 'utils/contexts/StoreContext';

export const Cart = () => {
  const { items, openCartPanel } = useCart();

  return (
    <div
      className="relative flex items-center justify-center cursor-pointer"
      onClick={openCartPanel}
    >
      <ShoppingBagIcon className="w-6 h-6" aria-hidden={false} />
      {items.length > 0 && (
        <div className="absolute top-0 right-0 translate-x-0.5 flex items-center justify-center w-3 h-3 bg-red-500 rounded-full text-white text-[8px] font-bold">
          {items.length > 9 ? '9+' : items.length}
        </div>
      )}
    </div>
  );
};
