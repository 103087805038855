import { Avatar, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from 'components/atoms/Button';
import Iconify from 'components/atoms/Iconify';
import Logo from 'components/atoms/Logo';
import Scrollbar from 'components/atoms/Scrollbar';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useAuth } from 'utils/contexts/AuthContext';
import { useBoolean } from 'utils/hooks/useBoolean';
import { NAV } from '..';
import { type NavProps } from '../types';
import NavList from './nav-list';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem 0rem;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 1rem;
`;

const StyledStack = styled(Stack)`
  padding: 0 0 1.5rem;
`;

const Profile = () => {
  const { user } = useAuth();

  if (!user.id) return null;

  return (
    <StyledStack direction="row" alignItems="center" spacing={1}>
      <Avatar alt={user?.name} src={user?.picture} />
      <Typography variant="subtitle1">{user?.name}</Typography>
    </StyledStack>
  );
};

export default function NavMobile({ data }: NavProps) {
  const mobileOpen = useBoolean();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth();

  useEffect(() => {
    if (mobileOpen.value) {
      mobileOpen.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navList = useMemo(
    () =>
      data.filter((list) => {
        if (list.isAuthenticated && !isAuthenticated) {
          return false;
        }

        return true;
      }),
    [data, isAuthenticated]
  );

  return (
    <>
      <IconButton onClick={mobileOpen.onTrue} sx={{ ml: 1, color: 'inherit' }}>
        <Iconify icon="carbon:menu" />
      </IconButton>

      <Drawer
        anchor="right"
        open={mobileOpen.value}
        onClose={mobileOpen.onFalse}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_VERTICAL
          }
        }}
      >
        <Scrollbar>
          <Container>
            <LogoContainer>
              <Logo />
            </LogoContainer>

            <Container>
              <List component="nav" disablePadding>
                {navList.map((list) => (
                  <NavList
                    key={list.title}
                    data={list}
                    onClick={() => {
                      if (mobileOpen.value) {
                        mobileOpen.onFalse();
                      }
                    }}
                  />
                ))}
              </List>
            </Container>
            <Stack spacing={1.5} sx={{ p: 3 }}>
              <Profile />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => (isAuthenticated ? logout() : loginWithRedirect())}
              >
                {isAuthenticated ? 'Logout' : 'Login'}
              </Button>
            </Stack>
          </Container>
        </Scrollbar>
      </Drawer>
    </>
  );
}
