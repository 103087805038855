import { Link } from '@tanstack/react-router';
import { type FC } from 'react';

export const NotFound: FC = () => (
  <div className="flex flex-col items-center justify-center h-full space-y-5">
    <p className="mt-20 text-sm font-medium text-gray-700">404</p>
    <h1 className="mt-3 text-lg font-semibold text-gray-900">Page not found</h1>
    <p className="mt-3 text-sm text-gray-700">
      Sorry, we couldn’t find the page you’re looking for.
    </p>
    <Link
      to="/"
      className="mt-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
    >
      Home
    </Link>
  </div>
);
