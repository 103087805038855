import { z } from 'zod';

export const ProductSchema = z.object({
  productId: z.string(),
  pricingId: z.string(),
  price: z.number(),
  quantity: z.number()
});

const ConfigSchema = z.object({
  fontFamily: z.string(),
  borderRadius: z.number(),
  navType: z.enum(['light', 'dark']),
  presetColor: z.string(),
  outlinedFilled: z.boolean()
});

export const CheckoutSchema = z.object({
  sessionId: z.string().optional()
});

const RedirectParamsSchema = z.object({
  path: z.string()
});

/* ------------------------------------ x ----------------------------------- */

export enum StorageKeys {
  CART = 'cart',
  APP_CONFIG = 'app_config',
  CHECKOUT = 'checkout',
  REDIRECT = 'redirect'
}

export const StorageSchema = {
  [StorageKeys.CART]: z.array(ProductSchema),
  [StorageKeys.APP_CONFIG]: ConfigSchema,
  [StorageKeys.CHECKOUT]: CheckoutSchema,
  [StorageKeys.REDIRECT]: RedirectParamsSchema
};
