import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { alpha, styled } from '@mui/material/styles';

import { Link } from '@tanstack/react-router';
import Iconify from 'components/atoms/Iconify';
import { type NavItemProps, type NavItemStateProps } from '../types';

const StyledNavItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})<NavItemStateProps>(({ active, theme }) => ({
  ...theme.typography.body1,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(1.5),
  color: theme.palette.text.primary,
  fontWeight: theme.typography.fontWeightMedium,
  fontFamily: theme.typography.fontFamily,
  ...(active && {
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    )
  })
}));

// ----------------------------------------------------------------------

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(
  ({ title, to, open, active, hasChild, externalLink, ...other }, ref) => {
    const renderContent = (
      <StyledNavItem ref={ref} active={active} {...other}>
        <Box component="span" sx={{ flexGrow: 1 }}>
          {title}
        </Box>

        {hasChild && (
          <Iconify
            width={16}
            icon={open ? 'carbon:chevron-down' : 'carbon:chevron-right'}
            sx={{ ml: 0.75 }}
          />
        )}
      </StyledNavItem>
    );

    if (hasChild) {
      return renderContent;
    }

    if (externalLink) {
      return (
        <Link href={to} target="_blank" rel="noopener">
          {renderContent}
        </Link>
      );
    }

    return <Link to={to}>{renderContent}</Link>;
  }
);

export default NavItem;

// ----------------------------------------------------------------------
