import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute(
  '/_auth/_dashboard/dashboard/assessments/_question/question/create/$assessmentId'
)({
  validateSearch: (search) =>
    z
      .object({
        topic: z.string().optional()
      })
      .parse(search),
  component: lazyRouteComponent(() => import('pages/Authenticated/Question/Create'))
});

export const useCreateQuestionParams = () => {
  const search = Route.useSearch();

  return {
    topic: search.topic
  };
};
