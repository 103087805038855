import { useEffect, useState } from 'react';
import { type z } from 'zod';
import { type StorageKeys, StorageSchema } from './types';

interface StoredValue<T> {
  value: T;
  expiry: number | null;
}

export const useLocalStorage = <T extends StorageKeys>(
  key: T,
  defaultValue: z.infer<(typeof StorageSchema)[T]>,
  ttl?: number
) => {
  const schema = StorageSchema[key];

  if (!schema) {
    throw new Error(`No schema defined for key: ${key}`);
  }

  const [value, setValue] = useState<z.infer<typeof schema>>(() => {
    const storedValue = localStorage.getItem(key);

    if (storedValue === null) {
      return defaultValue;
    }
    try {
      const parsedStoredValue = JSON.parse(storedValue);

      if (parsedStoredValue.expiry && parsedStoredValue.expiry < Date.now()) {
        localStorage.removeItem(key);
        return defaultValue;
      }
      const parsedValue = parsedStoredValue.value;
      return schema.parse(parsedValue) ? parsedValue : defaultValue;
    } catch (e) {
      return defaultValue;
    }
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (e.storageArea === localStorage && e.key === key) {
        const newValue = e.newValue ? JSON.parse(e.newValue) : e.newValue;
        if (newValue && newValue.expiry && newValue.expiry < Date.now()) {
          setValue(defaultValue);
          localStorage.removeItem(key);
        } else {
          setValue(newValue ? newValue.value : newValue);
        }
      }
    };
    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (
    newValue:
      | z.infer<typeof schema>
      | ((val: z.infer<typeof schema>) => z.infer<typeof schema>)
  ) => {
    setValue((currentValue) => {
      const result =
        typeof newValue === 'function'
          ? (
              newValue as (
                val: z.infer<(typeof StorageSchema)[T]>
              ) => z.infer<(typeof StorageSchema)[T]>
            )(currentValue)
          : newValue;
      if (schema.safeParse(result).success) {
        const valueToStore: StoredValue<z.infer<(typeof StorageSchema)[T]>> = {
          value: result,
          expiry: ttl ? Date.now() + ttl : null
        };
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }
      return result;
    });
  };

  return [value, setValueInLocalStorage] as const;
};
