import { z } from 'zod';

export const ProductTypeSchema = z.enum([
  'course'
  // TODO: Uncomment the following types when they are implemented
  // 'bundle'
  // 'physical_item',
  // 'digital_item'
  //  'service'
  // 'practice_exam'
]);

export enum ProductSubscription {
  ONE_TIME = 'ONE_TIME',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

const ProductDetailsSchema = z.object({
  title: z.string(),
  description: z.string(),
  seoKeywords: z.string(),
  type: ProductTypeSchema,
  courseId: z.string().cuid(),
  categories: z.array(z.string().cuid()),
  active: z.boolean(),
  visible: z.boolean()
});

const IntervalSchema = z.enum(['day', 'week', 'month', 'year']);

const PricingSchema = z.object({
  price: z.number().positive(),
  currency: z.string(),
  isSubscription: z.enum([
    ProductSubscription.ONE_TIME,
    ProductSubscription.SUBSCRIPTION
  ]),
  interval: IntervalSchema.optional(),
  intervalCount: z.number().positive().optional()
});

export const CourseDataSchema = z.object({
  highlights: z.array(z.object({ highlight: z.string() })),
  prerequisites: z.array(z.object({ prerequisite: z.string() })),
  learningOutcomes: z.array(z.object({ outcome: z.string() })),
  faqs: z.array(z.object({ question: z.string(), answer: z.string() }))
});

// This is a schema for the data that is stored in the database
// We can have variations for different types of products
// const ProductDataSchema = CourseDataSchema;

export const ProductSchema = z.object({
  pricing: z.array(PricingSchema),
  data: CourseDataSchema,
  ...ProductDetailsSchema.shape
});

export type ProductType = z.infer<typeof ProductTypeSchema>;
export type Product = z.infer<typeof ProductSchema>;
export type ProductDetails = z.infer<typeof ProductDetailsSchema>;
export type Pricing = z.infer<typeof PricingSchema>;
export type Interval = z.infer<typeof IntervalSchema>;
