import { authz } from '@simplify-aviation/shared/auth';
import { useCheckUserEnrollment } from 'api/enrollments';
import { useProducts } from 'api/products';
import { ConfirmationDialogMinimal } from 'components/atoms/Dialogs/ConfirmationDialog/minimal';
import { CartPopover } from 'features/Shop/Cart/CartPopover';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type ReactNode
} from 'react';
import { StorageKeys, type ProductSchema } from 'utils/hooks/localStorage/types';
import { useLocalStorage } from 'utils/hooks/localStorage/useLocalStorage';
import { type z } from 'zod';
import { useAuth } from './AuthContext';

type CartItem = z.infer<typeof ProductSchema>;

type CartContextType = {
  items: CartItem[];
  addToCart: (item: CartItem) => void;
  removeFromCart: (item: { pricingId: string; quantity: number }) => void;
  clearCart: () => void;
  openCartPanel: () => void;
  closeCartPanel: () => void;
  isCartPanelOpen: boolean;
  cartTotal: number;
  isBulkEnrolEnabled: boolean;
};

const CartContext = createContext<CartContextType | undefined>(undefined);

export const useCart = () => {
  const context = useContext(CartContext);
  if (context) {
    return context;
  }
  throw new Error('useCart must be used within a CartProvider');
};

type CartProviderProps = {
  children: ReactNode;
};

export const CartProvider: React.FC<CartProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const products = useProducts();
  const checkEnrollment = useCheckUserEnrollment();
  const isBulkEnrolEnabled = !!user.permissions?.has(authz.bulk_enroll);
  const [isCartPanelOpen, setIsCartPanelOpen] = useState(false);
  const [pendingCartItem, setPendingCartItem] = useState<
    (CartItem & { interval: 'month' | 'year' | 'week' | 'day' }) | null
  >(null);

  const [items, setItems] = useLocalStorage(StorageKeys.CART, [], 1000 * 60 * 60 * 48);

  const removeFromCart = useCallback(
    ({ pricingId, quantity }: { pricingId: string; quantity: number }) => {
      const existingProductIndex = items.findIndex(
        (item) => item.pricingId === pricingId
      );
      if (existingProductIndex !== -1) {
        const updatedItems = [...items];
        updatedItems[existingProductIndex].quantity -= quantity;
        if (updatedItems[existingProductIndex].quantity <= 0) {
          updatedItems.splice(existingProductIndex, 1);
        }
        setItems(updatedItems);
      }
    },
    [items, setItems]
  );

  const addToCart = useCallback(
    async ({ productId, pricingId, quantity, price }: CartItem) => {
      const product = products.find((p) => p.id === productId);
      if (!product) return;
      const pricing = product.pricing.find((p) => p.id === pricingId);
      if (!pricing) return;

      // Check if the user already has an active subscription to the course
      if (!isBulkEnrolEnabled && !!user.id) {
        const result = await checkEnrollment.mutateAsync(product.courseId);

        if (result) {
          alert('You already have an active subscription to this course');
          return;
        }
      }

      if (!pricing.isSubscription) {
        const existingProductIndex = items.findIndex(
          (item) => item.pricingId === pricingId
        );
        if (existingProductIndex !== -1) {
          const updatedItems = [...items];
          updatedItems[existingProductIndex].quantity += quantity;
          setItems(updatedItems);
        } else {
          setItems([...items, { productId, pricingId, quantity, price }]);
        }
        return;
      }

      // find the subscription period by finding the first item that has isSubscription set to true
      const existingSubscription = items.find((item) => {
        const itemProduct = products.find((p) => p.id === item.productId);
        if (!itemProduct) return false;
        const itemPricing = itemProduct.pricing.find((p) => p.id === item.pricingId);
        if (!itemPricing) return false;
        return itemPricing.isSubscription;
      });

      if (existingSubscription) {
        const subscriptionInterval = products
          .find((p) => p.pricing.some((pr) => pr.id === existingSubscription.pricingId))
          ?.pricing.find((pr) => pr.id === existingSubscription.pricingId)?.interval;
        if (!subscriptionInterval) return;
        const newSubscriptionInterval = pricing.interval;

        if (subscriptionInterval !== newSubscriptionInterval) {
          setPendingCartItem({
            productId,
            pricingId,
            quantity,
            price,
            interval: newSubscriptionInterval
          });
        } else {
          const existingProductIndex = items.findIndex(
            (item) => item.productId === productId
          );

          if (existingProductIndex !== -1) {
            const updatedItems = [...items];
            if (!isBulkEnrolEnabled) return;
            updatedItems[existingProductIndex].quantity += quantity;
            setItems(updatedItems);
          } else {
            setItems([...items, { productId, pricingId, quantity, price }]);
          }
        }
      } else {
        // if the product is not is the cart then add
        setItems([...items, { productId, pricingId, quantity, price }]);
      }
    },

    [products, isBulkEnrolEnabled, user.id, items, checkEnrollment, setItems]
  );

  const handleConfirmAddToCart = useCallback(() => {
    if (!pendingCartItem) return;

    const { productId, pricingId, quantity, price, interval } = pendingCartItem;

    const updatedItems = items.map((item) => {
      const newPricing = products
        .find((p) => p.id === item.productId)
        ?.pricing.find((pr) => pr.interval === interval);

      return {
        ...item,
        price: newPricing?.price ?? item.price,
        pricingId: newPricing?.id ?? item.pricingId,
        quantity
      };
    });

    const existingProductIndex = updatedItems.findIndex(
      (item) => item.productId === productId
    );

    if (existingProductIndex !== -1) {
      if (isBulkEnrolEnabled) {
        updatedItems[existingProductIndex].quantity += quantity;
      }
      setItems(updatedItems);
    } else {
      setItems([...updatedItems, { productId, pricingId, quantity, price }]);
    }

    setPendingCartItem(null);
  }, [items, pendingCartItem, products, setItems, isBulkEnrolEnabled]);

  const clearCart = useCallback(() => {
    setItems([]);
  }, [setItems]);

  const openCartPanel = () => {
    setIsCartPanelOpen(true);
  };

  const closeCartPanel = () => {
    setIsCartPanelOpen(false);
  };

  const cartTotal = useMemo(
    () => items.reduce((acc, item) => acc + item.price * item.quantity, 0),
    [items]
  );

  return (
    <CartContext.Provider
      value={{
        items,
        addToCart,
        removeFromCart,
        clearCart,
        openCartPanel,
        closeCartPanel,
        isCartPanelOpen,
        cartTotal,
        isBulkEnrolEnabled
      }}
    >
      <CartPopover />
      {children}
      <ConfirmationDialogMinimal
        isOpen={!!pendingCartItem}
        handleClose={() => setPendingCartItem(null)}
        handleConfirm={handleConfirmAddToCart}
        color="primary"
        title="Attention"
        description="You can only have one type of subscription interval for all courses in your cart at a time. Would you like to update all subscriptions?"
      />
    </CartContext.Provider>
  );
};
