import { Outlet } from '@tanstack/react-router';
import { useVerifyEmailMutation } from 'api/user';
import Button from 'components/atoms/Button';
import { notify } from 'lib/Notify';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAuth } from 'utils/contexts/AuthContext';

const Container = styled.div`
  align-content: center;
  display: grid;
  gap: 1.8rem;
  height: 100%;
  margin: 0 auto;
  max-width: 40rem;
  padding: 2rem;
  place-items: center;
`;

export const VerifyEmail = () => {
  const { user, getAccessTokenSilently } = useAuth();
  const [isInactive, setIsInactive] = useState(false);
  const emailVerify = useVerifyEmailMutation();

  useEffect(() => {
    const refreshToken = () => {
      if (user.email_verified) return;
      getAccessTokenSilently({ cacheMode: 'off' });
    };

    refreshToken();

    const handleWindowFocus = () => refreshToken();

    window.addEventListener('focus', handleWindowFocus);
    return () => window.removeEventListener('focus', handleWindowFocus);
  }, [user.email_verified, getAccessTokenSilently]);

  const handleResendEmail = async () => {
    if (isInactive) {
      notify('Please wait a few minutes before resending the email', { type: 'error' });
      return;
    }
    // don't let them spam the button only allow once every 1 minute
    emailVerify.mutate(undefined, {
      onSuccess: () => {
        notify('Verification email sent');
        setIsInactive(true);
        setTimeout(() => setIsInactive(false), 60000);
      },
      onError: (error) => {
        notify('Error resending verification email', { type: 'error' });
        // eslint-disable-next-line no-console
        console.error('Error resending verification email:', error);
      }
    });
  };

  if (!user.email_verified) {
    return (
      <Container>
        <h1 className="text-2xl font-semibold text-gray-800">Verify your email</h1>
        <p className="text-gray-600">
          We have sent you an email to verify your email address. Please check your inbox
          and click on the link to verify your email.
        </p>

        <Button onClick={handleResendEmail} disabled={emailVerify.isLoading}>
          {emailVerify.isLoading ? 'Sending...' : 'Resend Email'}
        </Button>
      </Container>
    );
  }
  return <Outlet />;
};
