import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
  type WithAuthenticationRequiredOptions
} from '@auth0/auth0-react';
import { getUserAuthz } from '@simplify-aviation/shared/auth';
import { useLoginQuery } from 'api/user';
import LoadingScreen from 'components/atoms/LoadingScreen';
import {
  useCallback,
  useEffect,
  useState,
  type ComponentType,
  type FC,
  type PropsWithChildren
} from 'react';
import { env } from 'utils/config/env';
import { getReturnUrl } from 'utils/misc/paths';

// ==============================|| AUTH HOOKS ||============================== //

export const useAuthInit = () => {
  const auth = useAuth0();

  if (!auth) throw new Error('useAuth must be used within an AuthProvider.');

  if (auth.error) throw auth.error;

  const getAccessToken = useCallback(async () => {
    if (auth.isAuthenticated) {
      return auth.getAccessTokenSilently();
    }

    return null;
  }, [auth]);

  return { ...auth, getAccessToken, authSub: auth.user?.sub ?? '' };
};

export const useAuth = () => {
  const auth = useAuthInit();

  const login = useLoginQuery({
    enabled: auth.isAuthenticated,
    firstName: auth.user?.given_name ?? '',
    lastName: auth.user?.family_name ?? '',
    email: auth.user?.email ?? '',
    image: auth.user?.picture ?? ''
  });

  const user = {
    ...auth.user,
    ...(login.data ? { ...login.data.user, ...getUserAuthz(login.data?.user) } : {}),
    image: auth.user?.picture ?? auth.user?.image
  };

  return {
    ...auth,
    user,
    isAuthenticated: auth.isAuthenticated && !!login.data?.user.id,
    isLoading: (login.isLoading && auth.isAuthenticated) || auth.isLoading,
    isError: auth.error || login.isError,
    errorMessage: auth.error?.message ?? login.error?.message ?? ''
  };
};

export const useAuthToken = () => {
  const [token, setToken] = useState<string>(''); // State to store the token
  const { getAccessToken } = useAuth();

  useEffect(() => {
    // Fetch the token and set it in the state
    const fetchToken = async () => {
      const accessToken = await getAccessToken();
      setToken(accessToken ?? '');
    };

    fetchToken();
  }, [getAccessToken]);

  return { token };
};

export type User = ReturnType<typeof useAuth>['user'];

export const AuthContext: FC<PropsWithChildren> = ({ children }) => {
  // TODO: remove hardcoded organisationId and use the url to fetch the id
  const organisationId = env.auth0Organisation;
  return (
    <Auth0Provider
      domain={env.auth0Domain}
      clientId={env.auth0ClientId}
      authorizationParams={{
        audience: env.auth0Audience,
        redirect_uri: `${window.location.origin}/login`,
        organization: organisationId
      }}
    >
      {children}
    </Auth0Provider>
  );
};

// ==============================|| AUTH HOC ||============================== //

export const withAuthRequired =
  <P extends object>(
    Component: ComponentType<P>,
    options: WithAuthenticationRequiredOptions = {}
  ): FC<P> =>
  (props: P) =>
    withAuthenticationRequired(Component, {
      onRedirecting: () => <LoadingScreen />,
      returnTo: getReturnUrl,
      ...options
    })(props);
