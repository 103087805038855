import { createContext, type ReactNode } from 'react';

// project import
import { useLocalStorage } from '../hooks/localStorage/useLocalStorage';

// types
import { type PaletteMode } from '@mui/material';
import { useAuth } from './AuthContext';
import { StorageKeys } from 'utils/hooks/localStorage/types';

const defaultConfig: {
  fontFamily: string;
  borderRadius: number;
  navType: PaletteMode;
  presetColor: string;
  outlinedFilled: boolean;
  // locale: string;
} = {
  fontFamily: 'Montserrat, sans-serif',
  borderRadius: 4,
  navType: 'light',
  presetColor: 'default',
  outlinedFilled: true
  // locale: 'en'
};

// initial state
const initialState: {
  fontFamily: string;
  borderRadius: number;
  navType: PaletteMode;
  presetColor: string;
  outlinedFilled: boolean;
  // locale: string;
  onChangeMenuType: (navType: PaletteMode) => void;
  onChangePresetColor: (presetColor: string) => void;
  onChangeFontFamily: (fontFamily: string) => void;
} = {
  ...defaultConfig,
  onChangeMenuType: () => {},
  onChangePresetColor: () => {},
  onChangeFontFamily: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }: { children: ReactNode }) {
  useAuth();
  const [config, setConfig] = useLocalStorage(StorageKeys.APP_CONFIG, {
    fontFamily: initialState.fontFamily,
    borderRadius: initialState.borderRadius,
    navType: initialState.navType,
    presetColor: initialState.presetColor,
    outlinedFilled: initialState.outlinedFilled
  });

  const onChangeMenuType = (navType: PaletteMode) => {
    setConfig({
      ...config,
      navType
    });
  };

  const onChangePresetColor = (presetColor: string) => {
    setConfig({
      ...config,
      presetColor
    });
  };

  const onChangeFontFamily = (fontFamily: string) => {
    setConfig({
      ...config,
      fontFamily
    });
  };

  return (
    <ConfigContext.Provider
      value={{
        ...config,
        onChangeMenuType,
        onChangePresetColor,
        onChangeFontFamily
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export { ConfigContext, ConfigProvider };
