import { getImagePath, imageSizes } from '@simplify-aviation/shared/utils/paths';
import DefaultImage from 'assets/images/courses/default-product-card.webp';
import { useEffect, useId, useState } from 'react';
import styled from 'styled-components';
import { env } from 'utils/config/env';
import Image from '../Image';

const Container = styled.div`
  height: 100%;
  width: 100%;
`;

const ResponsiveImage = ({
  imagePath,
  imageName,
  className
}: {
  imagePath: string;
  imageName: string;
  className?: string;
}) => {
  const [imageSize, setImageSize] = useState<number>();

  const id = useId();

  useEffect(() => {
    const updateImageSize = () => {
      const containerWidth = document?.getElementById(id)?.clientWidth;
      if (!containerWidth) return;
      const size = imageSizes.find((s) => s >= containerWidth);
      if (!size) return;

      setImageSize(size);
    };

    window.addEventListener('resize', updateImageSize);
    updateImageSize();
    return () => {
      window.removeEventListener('resize', updateImageSize);
    };
  }, [id]);

  return (
    <Container id={id}>
      {imageSize && (
        <Image
          className={className}
          src={getImagePath({
            path: imagePath,
            size: imageSize,
            domain: env.resourcesDomain
          })}
          alt={imageName}
          onError={(e) => {
            (e.target as HTMLImageElement).src = DefaultImage;
          }}
        />
      )}
    </Container>
  );
};

export default ResponsiveImage;
