import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/_dashboard/dashboard/courses/')({
  component: lazyRouteComponent(
    () => import('pages/Authenticated/Course/UserCourseList')
  ),
  validateSearch: (params) => z.object({ search: z.string().optional() }).parse(params)
});

export const useCourseListParams = () => {
  const { search } = Route.useSearch();
  const navigate = Route.useNavigate();
  return { search: search || '', navigate };
};
