import { type PaginationSort } from '@simplify-aviation/shared/utils/pagination.types';
import { useNavigate } from '@tanstack/react-router';
import { notify } from 'lib/Notify';
import { useProductParams } from 'routes/_landing/shop.$productType.$productUrl';
import { useProductId } from 'utils/hooks/useParams';
import { api } from './trpc';

export const useProductInvalidation = () => {
  const apiUtils = api.useUtils();

  const invalidateList = () => {
    apiUtils.store.catalog.invalidate();
    apiUtils.store.product.invalidate();
  };

  const invalidateOne = (id: string) => {
    apiUtils.store.product.get.invalidate(id);
    apiUtils.store.catalog.getCourse.invalidate();
    invalidateList();
  };

  return {
    invalidateList,
    invalidateOne
  };
};

export const useProducts = () => api.store.catalog.list.useSuspenseQuery()[0];
export type ShopProducts = ReturnType<typeof useProducts>;

export const useProductAdminView = (params: PaginationSort) =>
  api.store.product.list.useSuspenseQuery(params)[0];

export const useGetOneProduct = () => {
  const id = useProductId();
  return api.store.product.get.useSuspenseQuery(id)[0];
};

export const useCourseProduct = () => {
  const { params } = useProductParams();
  return api.store.catalog.getCourse.useSuspenseQuery({
    url: params.productUrl
  })[0];
};

export const useCreateProductMutation = () => {
  const { invalidateList } = useProductInvalidation();
  const navigate = useNavigate();
  return api.store.product.create.useMutation({
    onSuccess: () => {
      invalidateList();
      navigate({ to: '/dashboard/products' });
    },
    onError: (error) => {
      notify(error.message, { type: 'error' });
    }
  });
};

export const useUpdateProductMutation = () => {
  const { invalidateOne } = useProductInvalidation();
  const navigate = useNavigate();
  return api.store.product.update.useMutation({
    onSuccess: (id) => {
      notify('Product updated successfully', { type: 'success' });
      invalidateOne(id);
      navigate({ to: '/dashboard/products' });
    },
    onError: (error) => {
      notify(error.message, { type: 'error' });
    }
  });
};

export const useDeleteProductMutation = () => {
  const { invalidateOne } = useProductInvalidation();
  return api.store.product.delete.useMutation({
    onSuccess: (id) => {
      notify('Product deleted successfully', { type: 'success' });
      invalidateOne(id);
    },
    onError: (error) => {
      notify(error.message, { type: 'error' });
    }
  });
};
