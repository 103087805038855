import { MONEY_SYMBOL } from 'utils/misc/constants';

export const getPrice = ({
  price,
  currency,
  isSubscription,
  interval = 'month',
  intervalCount = 1
}: {
  price: number;
  currency: string;
  isSubscription: boolean;
  interval?: string;
  intervalCount?: number;
  isShortVersion?: boolean;
}) => {
  const duration = intervalCount > 1 ? `${intervalCount} ${interval}s` : interval;

  return `${MONEY_SYMBOL}${price} ${currency} ${isSubscription ? `/ ${duration}` : ''}`;
};
