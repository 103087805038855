import { Button as ButtonBase, type ButtonProps } from '@mui/material';
import { type FC } from 'react';
import styled, { css } from 'styled-components';

export type Color =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

type Size = 'x-small' | 'small' | 'medium' | 'large';

interface ButtonStylesProps {
  $padding: Size;
}

const paddingStyles = css<{ $padding: Size }>`
  ${({ $padding }) => {
    switch ($padding) {
      case 'x-small':
        return `
          font-size: 0.75rem;
          span {
            margin-right: 0.1rem;
          }
          min-width: 0.5rem;
          padding: 0.2rem 0.5rem;
        `;
      case 'small':
        return `
          min-width: 2rem;
          padding: 0.2rem 0.5rem;
        `;
      case 'medium':
        return `
          min-width: 5rem;
          padding: 0.4rem 1rem;
        `;
      case 'large':
        return `
          min-width: 6rem;
          padding: 1rem 2rem;
        `;
      default:
        return `
          min-width: auto;
          padding: 0.5rem 1rem;
        `;
    }
  }}
`;

const ButtonStyles = styled(ButtonBase)<ButtonStylesProps>`
  && {
    ${paddingStyles}
    text-decoration: none;
  }
`;

interface CustomButtonProps extends ButtonProps {
  children?: React.ReactNode;
  padding?: Size;
}

const Button: FC<CustomButtonProps> = ({
  onClick,
  children,
  variant = 'contained',
  padding = 'medium',
  ...props
}) => (
  <ButtonStyles
    disableElevation
    disableRipple
    onClick={onClick}
    {...props}
    $padding={padding}
    variant={variant}
  >
    {children}
  </ButtonStyles>
);

export default Button;
