import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWSClient, httpBatchLink, splitLink, wsLink } from '@trpc/client';
import { api } from 'api/trpc';
import { type FC, type PropsWithChildren } from 'react';
import superjson from 'superjson';
import { env } from 'utils/config/env';
import { useAuthInit } from './AuthContext';

const cacheTime = 1000 * 60 * 5; // 5 minutes

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,

      cacheTime,
      staleTime: cacheTime
    }
  }
});

const getWebsocketLink = () =>
  wsLink({
    client: createWSClient({
      url: `${env.serverUrl}`.replace(/^http(s)?:\/\//, 'ws$1://'),
      retryDelayMs: (retryAttempt) => Math.min(1000 * 2 ** retryAttempt, 30_000)
    })
  });

export const TRPCContext: FC<PropsWithChildren> = ({ children }) => {
  const { getAccessToken } = useAuthInit();

  const trpcClient = api.createClient({
    links: [
      splitLink({
        condition: ({ type }) => type === 'subscription',
        true: getWebsocketLink(),
        false: httpBatchLink({
          url: `${env.serverUrl}/trpc/v1`,
          async headers() {
            return {
              authorization: `Bearer ${await getAccessToken()}`
            };
          }
        })
      })
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transformer: superjson as any
  });

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </api.Provider>
  );
};
