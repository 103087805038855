import { useCart } from 'utils/contexts/StoreContext';
import { Product } from './Product';

export const Products = () => {
  const { items } = useCart();

  return (
    <div className="mt-8">
      <div className="flow-root">
        <ul className="-my-6 divide-y divide-gray-200">
          {items.length === 0 && (
            <li className="py-6 flex justify-center">
              <p className="text-sm text-gray-500">Your cart is empty</p>
            </li>
          )}
          {items.map(({ pricingId, productId, quantity }) => (
            <Product
              key={pricingId}
              pricingId={pricingId}
              productId={productId}
              quantity={quantity}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};
