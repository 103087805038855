/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useState } from 'react';

import Collapse from '@mui/material/Collapse';

import { type NavListProps, type NavSubListProps } from '../../types';
import NavItem from './nav-item';

function NavSubList({ data, depth, slotProps }: NavSubListProps) {
  return (
    <>
      {data.map((list) => (
        <NavList key={list.title} data={list} depth={depth + 1} slotProps={slotProps} />
      ))}
    </>
  );
}

export default function NavList({ data, depth, slotProps }: NavListProps) {
  const [openMenu, setOpenMenu] = useState(false);

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu((prev) => !prev);
    }
  }, [data.children]);

  // const handleCloseMenu = useCallback(() => {
  //   setOpenMenu(false);
  // }, []);

  return (
    <>
      <NavItem
        open={openMenu}
        onClick={handleToggleMenu}
        //
        title={data.title}
        to={data.to}
        icon={data.icon}
        info={data.info}
        roles={data.roles}
        caption={data.caption}
        disabled={data.disabled}
        //
        depth={depth}
        hasChild={!!data.children}
        externalLink={data.to.includes('http')}
        currentRole={slotProps?.currentRole}
        //
        // active={active}
        // className={active ? 'active' : ''}
        sx={{
          mb: `${slotProps?.gap}px`,
          ...(depth === 1 ? slotProps?.rootItem : slotProps?.subItem)
        }}
      />

      {!!data.children && (
        <Collapse in={openMenu} unmountOnExit>
          <NavSubList data={data.children} depth={depth} slotProps={slotProps} />
        </Collapse>
      )}
    </>
  );
}
