import { FaceBookIcon, InstagramIcon, XIcon, YoutubeIcon } from 'lib/Icons';

export const socialPlatforms = [
  {
    name: 'Facebook',
    url: 'https://facebook.com',
    icon: FaceBookIcon
  },
  {
    name: 'Instagram',
    url: 'https://instagram.com',
    icon: InstagramIcon
  },
  {
    name: 'X',
    url: 'https://x.com',
    icon: XIcon
  },
  {
    name: 'YouTube',
    url: 'https://youtube.com',
    icon: YoutubeIcon
  }
];
