export const rawConfig = {
  appVersion: import.meta.env.VITE_VERSION as string,

  serverUrl: import.meta.env.VITE_SERVER_URL as string,

  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
  auth0Audience: import.meta.env.VITE_AUTH0_AUDIENCE as string,
  auth0Organisation: import.meta.env.VITE_AUTH0_ORGANISATION as string | undefined,

  resourcesDomain: import.meta.env.VITE_RESOURCES_DOMAIN as string,
  environment: import.meta.env.VITE_ENVIRONMENT as string,
  stripePK: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY as string
};

if (!rawConfig.serverUrl) {
  throw new Error('VITE_SERVER_URL is required');
}

if (!rawConfig.auth0Domain) {
  throw new Error('VITE_AUTH0_DOMAIN is required');
}

if (!rawConfig.auth0ClientId) {
  throw new Error('VITE_AUTH0_CLIENT_ID is required');
}

if (!rawConfig.auth0Audience) {
  throw new Error('VITE_AUTH0_AUDIENCE is required');
}

if (!rawConfig.resourcesDomain) {
  throw new Error('VITE_RESOURCES_DOMAIN is required');
}

if (!rawConfig.environment) {
  throw new Error('VITE_ENVIRONMENT is required');
}

if (!rawConfig.stripePK) {
  throw new Error('VITE_STRIPE_PUBLISHABLE_KEY is required');
}

export const env = rawConfig;
