import {
  background,
  blue,
  common,
  dark,
  error,
  grey,
  indigo,
  orange,
  success,
  warning
} from 'themes/colors';

export const primary = {
  // paper & background
  background,
  common,

  // primary
  primary: indigo,

  // secondary
  secondary: blue,

  // tertiary
  tertiary: orange,

  // success
  success,

  // error
  error,

  // warning
  warning,

  // grey
  grey,

  dark
};
