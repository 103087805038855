import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (
  message: string,
  options: {
    type?: 'info' | 'success' | 'warning' | 'error';
  } = {}
) => toast[options.type ?? 'info'](message);

export const ToastProvider = () => <ToastContainer draggable />;
