import { Outlet } from '@tanstack/react-router';
import { type FC, type PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Footer } from './components/Footer';
import Header from './components/Header';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100dvh;
`;

const LandingPage: FC<PropsWithChildren> = ({ children }) => (
  <Container>
    <Header />
    <main>{children || <Outlet />}</main>
    <Footer />
  </Container>
);

export default LandingPage;
