import Button from 'components/atoms/Button';
import { useEffect, useRef, type FC } from 'react';
import styled from 'styled-components';
import { useAuth } from 'utils/contexts/AuthContext';

const ErrorScreenContainer = styled.div<{ $isMultiline: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100dvh;
  justify-content: center;
  margin: auto;
  max-width: 80%;
  padding: 4rem 0;
  width: ${(p) => (p.$isMultiline ? '80%' : '60rem')};
`;

const ErrorLinksContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 4rem;
`;

const ErrorScreenContent = styled.div`
  font-size: 1.6rem;
  padding: 4rem 6rem;
  text-align: left;

  > :first-child {
    margin-top: 0;
  }
`;

export const ErrorScreen: FC<{
  error: {
    name: string;
    message: string;
  };
  onResetError?: () => void;
}> = ({ onResetError, error }) => {
  const { logout } = useAuth();
  const containerRef = useRef<HTMLDivElement>(null);

  /**
   * Refresh the browser if the user gets an error message to say they are offline,
   * but then goes back online.
   */
  useEffect(() => {
    const handleOnline = () => {
      window.location.reload();
    };
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const isChunkLoadError = error.name === 'ChunkLoadError';

  useEffect(() => {
    if (isChunkLoadError) {
      window.location.reload();
    }
  }, [isChunkLoadError]);

  return (
    <ErrorScreenContainer
      ref={containerRef}
      data-testid="content-container"
      $isMultiline={false}
    >
      <ErrorScreenContent>
        <div data-testid="error-message">{error.message}</div>

        <ErrorLinksContainer>
          <Button
            onClick={() => {
              onResetError?.();
              window.history.pushState({}, '', '/');
              window.location.reload();
            }}
          >
            Back Home
          </Button>

          <Button variant="outlined" onClick={() => logout()}>
            Log out
          </Button>
        </ErrorLinksContainer>
      </ErrorScreenContent>
    </ErrorScreenContainer>
  );
};
