import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

const paramSchema = z.object({
  productId: z.string().cuid()
});

export const Route = createFileRoute(
  '/_auth/_dashboard/dashboard/products/edit/$productId'
)({
  parseParams: (params) => paramSchema.parse(params),
  component: lazyRouteComponent(() => import('pages/Authenticated/Products/Edit'))
});
