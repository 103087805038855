import { z } from 'zod';

export const directiveComponents = {
  responsive_image: 'Image',
  video: 'Video',
  checklist: 'Checklist',
  syllabus: 'Syllabus'
};

export const sizeSchema = z.enum([
  'x-small',
  'small',
  'medium',
  'large',
  'x-large',
  'full'
]);

export const AttributesSchema = z
  .object({
    url: z.string(),
    height: z.string(),
    width: z.string(),
    alignment: z.enum(['left', 'center', 'right']),
    inline: z.boolean(),
    size: sizeSchema,
    type: z.string()
  })
  .partial();

export type DirectiveAttributes = z.infer<typeof AttributesSchema>;
export type DirectiveComponentName = keyof typeof directiveComponents;
export type DirectiveName = '' | DirectiveComponentName;
export type Size = z.infer<typeof sizeSchema>;

export const isDirectiveComponentName = (name: string): name is DirectiveComponentName =>
  name in directiveComponents;

export const isDirectiveAttribute = (
  attributeName: string
): attributeName is keyof DirectiveAttributes => attributeName in AttributesSchema.shape;

export interface DirectiveProps extends DirectiveAttributes {
  content: string;
  label?: string;
}
