export const imageSizes = [300, 600, 1200, 1920, 3840];
export const getImagePath = ({
  path,
  size,
  domain
}: {
  path: string;
  size: number;
  domain: string;
}) => `${domain}/${path}/${size}.webp`;
