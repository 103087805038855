import { z } from 'zod';

// !important: ensure that the permissions/roles are not in the database if changing/removing
export const AuthzSchema = z.enum([
  'dashboard:manage:users',
  'dashboard:manage:courses',
  'organisation:full-access',
  'organisation:read:roles',
  'organisation:assign:role',
  'organisation:assign:permission',

  'roles:view:all',
  'roles:view:admin',
  'roles:view:course-editor',
  'roles:view:learner',
  'roles:view:instructor',

  'assessments:view:all',
  'assessments:delete:all',
  'assessments:update',
  'assessment:attempt',

  'course:view:all',
  'course:edit:all',
  'course:assign:users',

  'media:add',

  'products:all',
  'products:admin:view',
  'products:admin:edit',
  'products:admin:create',

  'bulk:enroll'
]);
export const RoleSchema = z.enum([
  'SUPER_ADMIN',
  'ADMIN',
  'COURSE_EDITOR',
  'INSTRUCTOR',
  'LEARNER'
]);
export const RolesSchema = z.array(
  z.object({
    id: RoleSchema,
    name: z.string(),
    description: z.string(),
    requiredPermissions: z.array(AuthzSchema)
  })
);

const PermissionsSchema = z.array(
  z.object({
    id: AuthzSchema,
    name: z.string(),
    description: z.string(),
    roles: z.array(RoleSchema)
  })
);

export type Authz = z.infer<typeof AuthzSchema>;
export type Permissions = z.infer<typeof PermissionsSchema>;
export type Role = z.infer<typeof RoleSchema>;
export type Roles = z.infer<typeof RolesSchema>;
