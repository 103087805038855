import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

export const Route = createFileRoute('/_auth/_coursePlayer/courses/$courseId')({
  validateSearch: (search) =>
    z
      .object({
        lessonId: z.string().optional()
      })
      .parse(search),
  component: lazyRouteComponent(() => import('pages/Authenticated/Course/CoursePlayer'))
});

export const useCourseParams = () => {
  const { courseId } = Route.useParams();
  const { lessonId } = Route.useSearch();
  return { courseId, lessonId };
};

export const useNavigateCourse = () => {
  const navigate = Route.useNavigate();
  return {
    navigateToLesson: (lessonId: string) => navigate({ search: { lessonId } })
  };
};
