import Collapse from '@mui/material/Collapse';

import { useBoolean } from 'utils/hooks/useBoolean';
import NavSectionVertical from '../nav-section-vertical';
import { type NavListProps } from '../types';
import NavItem from './nav-item';

// ----------------------------------------------------------------------

export default function NavList({
  data,
  onClick
}: NavListProps & {
  onClick: () => void;
}) {
  const menuOpen = useBoolean();

  return (
    <>
      <NavItem
        open={menuOpen.value}
        onClick={() => {
          menuOpen.onToggle();
          onClick();
        }}
        //
        title={data.title}
        to={data.to}
        //

        hasChild={!!data.children}
        externalLink={data.to.includes('http')}
      />

      {!!data.children && (
        <Collapse in={menuOpen.value} unmountOnExit>
          <NavSectionVertical
            data={data.children}
            slotProps={{
              rootItem: {
                pl: 1,
                minHeight: 30
              },
              subheader: {
                pl: 1
              }
            }}
          />
        </Collapse>
      )}
    </>
  );
}
