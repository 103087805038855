import Loader from 'components/atoms/Loader';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  height: 100dvh;
  width: 100vw;
`;

const LoadingScreen = () => (
  <LoadingContainer>
    <Loader />
  </LoadingContainer>
);

export default LoadingScreen;
