import { useCallback, useEffect, useState, type ReactNode } from 'react';
import { useAuth } from 'utils/contexts/AuthContext';
import { ErrorScreen } from '../ErrorScreen';

const UnhandledErrorBoundary = ({ children }: { children?: ReactNode }) => {
  const auth = useAuth();
  const [error, setError] = useState<{
    name: string;
    message: string;
  } | null>(null);

  const promiseRejectionHandler = useCallback((event: PromiseRejectionEvent) => {
    setError(event.reason);
  }, []);

  const handleResetError = useCallback(() => {
    setError(null);
    window.history.pushState({}, '', window.location.pathname);
    if (auth.isAuthenticated && auth.isError) {
      auth.logout();
    }
  }, [auth]);

  useEffect(() => {
    window.addEventListener('unhandledrejection', promiseRejectionHandler);

    return () => {
      window.removeEventListener('unhandledrejection', promiseRejectionHandler);
    };
  }, [promiseRejectionHandler]);

  return error ? (
    <ErrorScreen error={error} onResetError={handleResetError} />
  ) : (
    <>{children}</>
  );
};

export default UnhandledErrorBoundary;
