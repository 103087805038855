import { COMPANY_NAME } from 'utils/misc/constants';
import { socialPlatforms } from 'utils/misc/socials';

export const Footer = () => (
  <footer className="bg-transparent" aria-labelledby="footer-heading">
    <h2 id="footer-heading" className="sr-only">
      Footer
    </h2>
    <div className="mx-auto max-w-7xl px-6 pb-8 lg:px-8">
      {/* Bottom Bar */}
      <div className="mt-8 border-t border-gray-900/10 pt-8 md:flex md:items-center md:justify-between">
        <div className="flex space-x-6 md:order-2 gap-2">
          {socialPlatforms.map((item) => (
            <a
              key={item.name}
              href={item.url}
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden={true} />
            </a>
          ))}
        </div>
        <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
          &copy; {new Date().getFullYear()} {COMPANY_NAME}, Ltd. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
);
