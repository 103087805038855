import { Menu, Transition } from '@headlessui/react';
import { DashboardIcon, HomeIcon, LogoutIcon } from 'lib/Icons';
import { useState, type FC } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { type Routes } from 'types/router';
import { useAuth } from 'utils/contexts/AuthContext';
import Button from '../Button';
import Link from '../Link';

const AuthenticationButton: FC<{
  isMobileView?: boolean;
  hasHomeLink?: boolean;
  hasDashboardLink?: boolean;
}> = ({ isMobileView = false, hasDashboardLink, hasHomeLink }) => {
  const { isAuthenticated, logout, user, loginWithRedirect } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleLogout = () => {
    logout({
      logoutParams: { returnTo: window.location.origin }
    });
  };

  const menuItems: {
    name: string;
    link?: Routes;
    onClick?: () => void;
    icon: JSX.Element;
  }[] = [{ name: 'Sign out', onClick: handleLogout, icon: <LogoutIcon /> }];

  if (hasDashboardLink) {
    menuItems.unshift({
      name: 'Dashboard',
      link: '/dashboard/courses',
      icon: <DashboardIcon />
    });
  }

  if (hasHomeLink) {
    menuItems.unshift({ name: 'Home', link: '/', icon: <HomeIcon /> });
  }

  if (!isAuthenticated) {
    return (
      <Button
        variant="text"
        color="inherit"
        onClick={() => loginWithRedirect()}
        endIcon={<span aria-hidden="true">&rarr;</span>}
      >
        Login
      </Button>
    );
  }

  if (isMobileView) {
    return (
      <div className="flex flex-col justify-between">
        <div className="flex items-center gap-3">
          <img
            className="h-8 w-8 rounded-full"
            src={user.picture}
            alt={`${user.name}'s profile`}
          />
          <p className="text-sm font-semibold text-gray-900 truncate">{user.name}</p>
        </div>
        <div className="flex flex-col py-4">
          {menuItems.map((item) => (
            <div key={item.name} className="px-4 py-2 text-sm text-gray-700">
              {item.link ? (
                <Link to={item.link} className="flex gap-2">
                  {item.icon}
                  <span>{item.name}</span>
                </Link>
              ) : (
                <button className="flex gap-2" onClick={item.onClick}>
                  {item.icon}
                  <span>{item.name}</span>
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Menu as="div" className="relative ml-3">
      <div className="flex items-center gap-x-3">
        <Menu.Button
          onClick={() => setMenuOpen(!menuOpen)}
          className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="absolute -inset-1.5" />
          <span className="sr-only">Open user menu</span>
          <img className="h-8 w-8 rounded-full" src={user.picture} alt="" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        afterLeave={() => setMenuOpen(false)}
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {menuItems.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => {
                if (item.link) {
                  return (
                    <Link
                      to={item.link}
                      className={`${active ? 'bg-gray-100' : ''}  px-4 py-2 text-sm text-gray-700 flex gap-3`}
                    >
                      {item.icon}
                      <span>{item.name}</span>
                    </Link>
                  );
                }

                if (item.onClick) {
                  return (
                    <button
                      onClick={item.onClick}
                      className={`${active ? 'bg-gray-100' : ''}  px-4 py-2 text-sm text-gray-700 w-full text-left flex gap-3`}
                    >
                      {item.icon}
                      <span>{item.name}</span>
                    </button>
                  );
                }

                return <Fragment />;
              }}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AuthenticationButton;
