import { type Routes } from 'types/router';

export const homeLinks: {
  title: string;
  to: Routes;
  isAuthenticated?: boolean;
  isMobileOnly?: boolean;
}[] = [
  { title: 'Home', to: '/' },
  {
    title: 'Dashboard',
    to: '/dashboard/courses',
    isAuthenticated: true
  },
  { title: 'Courses', to: '/courses' },
  // { label: 'About', to: '/about' },
  // { label: 'Blog', to: '/blog' },

  { title: 'Contact', to: '/contact' }
];

export const getReturnUrl = (includeOrigin = false) => {
  if (includeOrigin) {
    return `${window.location.origin}/${window.location.href}${window.location.search}`;
  }
  return `${window.location.href}${window.location.search}`;
};
