import { AppBar, Box, Container, Stack, Toolbar } from '@mui/material';
import AuthenticationButton from 'components/atoms/AuthenticationButton';
import { Cart } from 'components/atoms/Cart';
import Link from 'components/atoms/Link';
import Logo from 'components/atoms/Logo';

import { type FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { bgBlur } from 'themes/css';
import { useOffSetTop } from 'utils/hooks/useOffsetTop';
import { useResponsive } from 'utils/hooks/useResponsive';
import { COMPANY_NAME } from 'utils/misc/constants';
import { homeLinks } from 'utils/misc/paths';
import NavDesktop from './desktop';
import NavMobile from './mobile';

const HomeLink = () => (
  <Link to="/" className="-m-1.5 p-1.5">
    <span className="sr-only">{COMPANY_NAME}</span>
    <Logo />
  </Link>
);

export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 80,
  H_DESKTOP_OFFSET: 80 - 16
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88
};

const StyledAppBar = styled(AppBar)`
  && {
    background-color: transparent;
    box-shadow: 0;
    color: ${({ theme }) => theme.palette.text.primary};
    position: fixed;
    transition: ${({ theme }) =>
      theme.transitions.create(['height', 'background-color'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
      })};
    z-index: 30;
  }
`;

const Header: FC = () => {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');

  return (
    <StyledAppBar elevation={0}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
          }),

          ...(offset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16
            }
          })
        }}
      >
        <Container
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ lineHeight: 0, position: 'relative' }}>
            <HomeLink />
          </Box>

          <>
            <Stack
              flexGrow={1}
              alignItems="center"
              sx={{
                height: 1,
                display: { xs: 'none', md: 'flex' }
              }}
            >
              <NavDesktop data={homeLinks} />
            </Stack>

            <Box sx={{ flexGrow: { xs: 1, md: 'unset' } }} />
          </>

          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <Cart />
            </Stack>

            {mdUp && <AuthenticationButton hasDashboardLink />}
          </Stack>

          {!mdUp && <NavMobile data={homeLinks} />}
        </Container>
      </Toolbar>

      {offset && (
        <Box
          sx={{
            left: 0,
            right: 0,
            bottom: 0,
            m: 'auto',
            height: 24,
            zIndex: -1,
            opacity: 0.48,
            borderRadius: '50%',
            position: 'absolute',
            width: `calc(100% - 48px)`,
            boxShadow: theme.customShadows.z8
          }}
        />
      )}
    </StyledAppBar>
  );
};

export default Header;
