import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

const paramsSchema = z.object({
  // syllabusId is a string that should be a number
  syllabusId: z.string().transform((val) => parseInt(val))
});

const searchSchema = z.object({
  identifier: z.string().optional(),
  level: z.string().optional(),
  parentIdentifier: z.string().optional()
});

export const Route = createFileRoute(
  '/_auth/_dashboard/dashboard/syllabus/edit/$syllabusId'
)({
  parseParams: (params) => paramsSchema.parse(params),
  validateSearch: (params) => searchSchema.parse(params),
  component: lazyRouteComponent(() => import('pages/Authenticated/Syllabus/Edit'))
});
