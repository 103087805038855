import { createFileRoute } from '@tanstack/react-router';
import LandingPage from 'components/_layouts/LandingPage';
import { VerifyEmail } from 'features/Auth/VerifyEmail';
import styled from 'styled-components';

const Container = styled.div`
  padding-top: 4.5rem;

  @media (min-width: 768px) {
    padding-top: 6rem;
  }
`;

export const Route = createFileRoute('/_auth/_profile')({
  component: () => (
    <LandingPage>
      <Container>
        <VerifyEmail />
      </Container>
    </LandingPage>
  )
});
