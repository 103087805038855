import CircularProgress from '@mui/material/CircularProgress';
import { type FC } from 'react';

const Loader: FC<{
  type?: 'determinate' | 'indeterminate';
  value?: number;
  size?: number | string;
}> = ({ value, type, size }) => (
  <div className="loader flex items-center justify-center h-full">
    <CircularProgress size={size} variant={type} value={value} />
  </div>
);

export default Loader;
