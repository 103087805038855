import { createFileRoute, lazyRouteComponent } from '@tanstack/react-router';
import { z } from 'zod';

const searchParams = z.object({
  search: z.string().optional(),
  category: z.string().optional()
});

export const Route = createFileRoute('/_landing/courses')({
  validateSearch: (search) => searchParams.parse(search),
  component: lazyRouteComponent(
    () => import('pages/Standard/CourseCatalog'),
    'CoursesCatalog'
  )
});

export const useCatalogSearch = () => {
  const params = Route.useSearch();
  const navigate = Route.useNavigate();

  return {
    search: { ...params, category: params.category ?? 'all' },
    navigate
  };
};
