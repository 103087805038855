import { createRouter, RouterProvider } from '@tanstack/react-router';
import { routeTree } from 'routeTree.gen';

const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const RouterContext = () => <RouterProvider router={router} />;
